// import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
// import { verticalSlideDown } from './Animations';
import './Navbar.css';

function Navbar (props) {
  const linkElements = [];

  for (const link of props.links) {
    linkElements.push(
      <Link to={link.to} key={link.text} className='navbar-link'>{link.text}</Link>
    );
  }

  return (
    <div className='navbar'>
      {linkElements}
    </div>
  );
}

export default Navbar;
