import { AnimatePresence, motion } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';
import { blackBoxAnimation, translateInOut } from './Animations';
import './App.css';
import BookReport from './BookReport/BookReport';
import Home from './MainPages/Home';
import Navbar from './Navbar';

function App () {
  const location = useLocation();

  function testPage (variants, text) {
    return (
      // <motion.div initial='initial' animate='animate' exit='exit' className='page' variants={variants}>Page</motion.div>
      <div className='page'>{text}</div>
    );
  }

  return (
    <div className='app'>
      <Navbar
        links={[
          {
            to: '/home',
            text: 'Home'
          },
          {
            to: '/projects',
            text: 'Projects'
          },
          {
            to: '/contact',
            text: 'Contact'
          }
        ]}
      />
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial='initial'
          animate='animate'
          exit='exit'
          variants={blackBoxAnimation()}
          key={location.pathname + '-black-box'}
          className='black-box'
          id='black-box'
        >
          {/* <motion.div
            initial='initial'
            animate='animate'
            exit='exit'
            variants={translateOutIn()}
            key={location.pathname + '-text'}
            className='transition-text'
          >
            Sophie Snow
          </motion.div> */}
          {/* <div className='transition-text'>Sophie Snow</div> */}
        </motion.div>
        <Routes location={location} key={location.pathname}>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/projects' element={testPage(translateInOut(), 'Projects')} />
          <Route path='/contact' element={testPage(translateInOut(), 'Contact')} />
          <Route path='/book-report' element={<BookReport />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
