import { motion } from 'framer-motion';
import { translateInOut } from '../Animations';
import pfp from '../assets/Sophie.png';
import { useState } from 'react';
import './Home.css';

function Home (props) {
  const [programmingSelection, setProgrammingSelection] = useState('frontend');

  return (
    <motion.div
      initial='initial'
      animate='animate'
      exit='exit'
      variants={translateInOut()}
      className='page homepage'
    >
      <div className='home-section home-header'>
        <div className='home-text'>
          <div className='home-title'>Sophie Snow</div>
          <div className='home-description'>
            Web designer, backend developer,<br />
            musician, vfx artist, and...<br />
            <br />
            Well, just scroll down.
          </div>
        </div>
        <img src={pfp} alt="Sophie's Picture" className='home-image' />
      </div>

      <div className='home-section'>
        <div className='home-box'>
          <div className='box-title'>
            <div className='box-title-text'>Programming</div>
            <div className='box-title-break' />
            <div className='box-title-link' onClick={() => setProgrammingSelection('frontend')}>Frontend</div>
            <div className='box-title-link' onClick={() => setProgrammingSelection('backend')}>Backend</div>
          </div>

          {
            (programmingSelection === 'frontend')
              ? (
                <div className='box-text'>
                  I've done many front-end development projects, including the website you're looking at right now. Most projects go unfinished, as I move between projects very quickly.

                  <ul>
                    <li>
                      <a href='https://solar-system-calculator.vercel.app/'>Solar System Calculator</a>
                    </li>
                    <li>
                      <a href='http://acupuncturecenteroflosalamos.org'>Acupuncture Center of Los Alamos</a>
                    </li>
                  </ul>
                </div>
                )
              : (
                <div className='box-text'>
                  While I've only done front-end development for the last few years, I've been doing back-end development for much, much longer. Most of my projects tend to be Discord bots, but I've also written chat applications, API bridges, command-line applications and browser add-ons (are those back-end?).

                  <ul>
                    <li>
                      <a href='https://github.com/SophieSnoww/Abbacus'>Abbacus</a>
                    </li>
                    <li>
                      <a href='https://github.com/SophieSnoww/Crystal-Florist'>Crystal Florist</a>
                    </li>
                    <li>
                      <a href='https://github.com/SophieSnoww/search-and-download'>Search and Download</a>
                    </li>
                    <li>
                      <a href='https://github.com/SophieSnoww/youtube-shorts-redirect'>Youtube Shorts Redirect</a>
                    </li>
                    <li>
                      <a href='https://github.com/SophieSnoww/ch.api'>CH.AI / CH.API</a>
                    </li>
                  </ul>
                </div>
                )
          }
        </div>

        {/* <div className='home-box'>
          <div className='box-title'>Music</div>
        </div>

        <div className='home-box'>
          <div className='box-title'>Music</div>
        </div>

        <div className='home-box'>
          <div className='box-title'>Music</div>
        </div>

        <div className='home-box'>
          <div className='box-title'>Music</div>
        </div> */}
      </div>

      <div className='home-footer'>
        sophie-snow.com © 2022 Sophie Snow
      </div>
    </motion.div>
  );
}

export default Home;
