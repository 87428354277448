export const transitionDuration = 1;

export function translate (direction = 'down', distance = null) {
  switch (direction) {
    case 'up':
      distance = distance || '0vh';
      return `translate(0vw, -${distance})`;
    case 'down':
      distance = distance || '0vh';
      return `translate(0vw, ${distance})`;
    case 'left':
      distance = distance || '0vw';
      return `translate(-${distance}, 0vh)`;
    case 'right':
      distance = distance || '0vw';
      return `translate(${distance}, 0vh)`;
  }
}

export function translateInOut (delay = 0, translation = translate('down', '15vh')) {
  return {
    initial: {
      transform: translation,
      opacity: 0
    },
    animate: {
      transform: translate(),
      opacity: 1,
      transition: {
        delay,
        duration: transitionDuration,
        timingFunction: 'ease-in'
      }
    },
    exit: {
      transform: translation,
      opacity: 0,
      transition: {
        delay,
        duration: transitionDuration,
        timingFunction: 'ease-out'
      }
    }
  };
}

export function translateOutIn (delay = 0, translation = translate('down', '15vh')) {
  return {
    initial: {
      transform: translate(),
      opacity: 1
    },
    animate: {
      transform: translation,
      opacity: 0,
      transition: {
        delay,
        duration: transitionDuration,
        timingFunction: 'ease-in'
      }
    },
    exit: {
      transform: translate(),
      opacity: 1,
      transition: {
        delay,
        duration: transitionDuration,
        timingFunction: 'ease-out'
      }
    }
  };
}

export function blackBoxAnimation () {
  return {
    initial: {
      left: '0vw',
      right: '0vw'
    },
    animate: {
      left: ['0vw', '100vw', '0vw'],
      right: ['0vw', '0vw', '100vw'],
      // left: '100vw',
      // right: '0vw',
      transition: {
        duration: transitionDuration,
        times: [0, 0.999999, 1],
        timingFunction: 'ease-in'
      }
    },
    exit: {
      left: '0vw',
      right: '0vw',
      // left: ['100vw', '0vw'],
      // right: ['0vw', '0vw'],
      transition: {
        duration: transitionDuration,
        // times: [0.99999999999, 1],
        timingFunction: 'ease-out'
      }
    }
  };
}
