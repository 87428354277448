import { motion } from 'framer-motion';
import { translateInOut } from '../Animations';
import './BookReport.css';
import cover from '../assets/the_toll.jpg';

function BookReport (props) {
  return (
    <motion.div
      initial='initial'
      animate='animate'
      exit='exit'
      variants={translateInOut()}
      className='page book-report-container'
    >
      <div className='book-report-container'>
        <div className='book-report-basic-info'>
          <div className='book-title'>The Toll</div>
          <div className='book-author'>Neal Shusterman</div>
          <div className='book-overview'>
            <i>The Toll</i> is the third and final novel in the <i>Scythe</i> series.
          </div>
        </div>
        <img src={cover} alt='Book Cover' className='book-cover-image' />
      </div>

      <div className='book-report-container small'>
        <div className='book-report-header'>Genre & Plot</div>
        <div className='book-report-paragraph'>
          <i>The Toll</i> is a <b>sci-fi/dystopian</b> novel. The book is set centuries in the future, where the planet is ruled by an artificial intelligence, and death has been conquered. <i>The Toll</i> is the third book in a series, and it tells the story of two young adults, <b>Citra Terranova</b> and <b>Rowan Damish</b>, who died two years ago. They're revived, and realize the very thing that they were attempting to prevent in the past has come to fruition - <b>Robert Goddard</b>, the antagonist, has become the ruler of nearly the entire planet. The book also follows the story of <b>Greyson Tolliver</b>, a young adult turned religious martyr.
        </div>
      </div>

      <div className='book-report-container small characters'>
        <div className='book-report-header'>Characters</div>

        <div className='book-report-subcontainer'>
          <div className='book-report-subheader'>Citra Terranova</div>
          <div className='book-report-paragraph'>
            Citra grew up alongside <b>Rowan</b>, and is perceived as a figurehead for the movement to dethrone <b>Goddard</b>. She begins the novel by being discovered dead alongside Rowan in an undersea vault. She's revived, and has to flee from the country to escape Goddard. She discovers that Goddard intentionally sabotaged any space expedition, as he doesn't want to let humanity to leave earth.
          </div>
        </div>

        <div className='book-report-subcontainer'>
          <div className='book-report-subheader'>Rowan Damish</div>
          <div className='book-report-paragraph'>
            Rowan, while growing up alongside <b>Citra</b>, is perceived very differently. He's seen as a villain, the one who sunk a city and killed countless people in the process. We know this isn't true - <b>Goddard</b> was the one who did it - but Rowan is used as a scapegoat. When Rowan is revived from the undersea vault, he is captured by Goddard, who tours him around before attempting to burn him at the stake.
          </div>
        </div>

        <div className='book-report-subcontainer'>
          <div className='book-report-subheader'>Greyson Tolliver</div>
          <div className='book-report-paragraph'>
            Greyson is the only person on the planet that <b>The Thunderhead</b> (the artificial intelligence that oversees the planet) speaks to. He's turned into a religious martyr, even though he isn't religious himself, and uses this to rally people against <b>Goddard</b>.
          </div>
        </div>

        <div className='book-report-subcontainer'>
          <div className='book-report-subheader'>Robert Goddard</div>
          <div className='book-report-paragraph'>
            Goddard is the main antagonist. He attempts to kill both <b>Citra</b> and <b>Rowan</b>, and his goal is to extend his reach of influence over the entire planet. He doesn't want humanity to escape the planet, because they wouldn't be under his control, and he explodes multiple rockets that <b>The Thunderhead</b> has built to travel into space.
          </div>
        </div>
      </div>

      <div className='book-report-container small'>
        <div className='book-report-header'>Techniques Used Well</div>

        <div className='book-report-subcontainer'>
          <div className='book-report-subheader'>The Unknown</div>
          <div className='book-report-paragraph'>
            The author expertly uses unknown information in the entire series. A lot of vital information isn't given to us directly, and we only learn it through characters speech or action. A great example of this is the reveal that <b>The Thunderhead</b> is building spaceships - we learn that The Thunderhead has some big plan, but we don't learn what it is until much, much later, and that reveal feels amazing as everything starts connecting.
          </div>
        </div>

        <div className='book-report-subcontainer'>
          <div className='book-report-subheader'>Emotional Realism</div>
          <div className='book-report-paragraph'>
            All of the characters in the novel feel very real, in more ways than one. I'd like to focus on emotions, though. Each character has emotional development, and it all feels realistic. Even <b>The Thunderhead</b> has emotional development - in the literal sense that it develops emotions. Many antagonists (namely Rand, a minor antagonist and follower of <b>Goddard</b>) emotionally develop and regret their actions, eventually turning on Goddard and ending his plans.
          </div>
        </div>
      </div>

      <div className='book-report-container small'>
        <div className='book-report-header'>Techniques Used Poorly</div>

        <div className='book-report-subcontainer'>
          <div className='book-report-subheader'>The Ending</div>
          <div className='book-report-paragraph'>
            The ending of the novel is one that many could find unsatisfying. Yes, many problems are solved, but it does end with almost all main characters either dead or escaping the planet on a spaceship. I, personally, didn't have a ton of issue with it, but I also don't have particularly high standards.
          </div>
        </div>

        <div className='book-report-subcontainer'>
          <div className='book-report-subheader'>Too Many Characters</div>
          <div className='book-report-paragraph'>
            I've only spoken about four of the 12 main characters (more or less). It's easy to get lost, and the book focuses a lot less on the main characters of the original novel: <b>Citra</b> and <b>Rowan</b>. Another character, <b>Jericho</b>, feels like the most "token ______ character" ever. Overall, it can get very muddled, and arguably loses focus from the main novel.
          </div>
        </div>
      </div>

      <div className='book-report-container small'>
        <div className='book-report-header'>How To...</div>

        <div className='book-report-subcontainer'>
          <div className='book-report-subheader'>Utilize The Positive</div>
          <div className='book-report-paragraph'>
            When writing, I think that the element of the unknown is a very powerful tool. It can be used poorly, but if used correctly, it can be an amazing storytelling device. It adds to the readers immersion, as they're finding the information out just like a character in the book would. Emotional realism is a difficult thing to get correct, and putting yourself into a real persons shoes is hard enough. If your characters don't feel real, though, they can feel one-dimensional and uninteresting to the reader. Development and realistic reactions to the situation they're in is a very important thing to keep in mind.
          </div>
        </div>

        <div className='book-report-subcontainer'>
          <div className='book-report-subheader'>Avoid The Negative</div>
          <div className='book-report-paragraph'>
            It's extremely difficult to come up with good endings, but it's important to keep in mind that the problems should feel solved. Characters just running away from problems doesn't make for a satisfying ending, and for many people, doesn't feel like it wraps anything up at all. As for having too many characters, it's easy to get caught up in the story and just want to add more and more (especially with a series of novels), but you should keep your original story in mind, and not add so many other plots or perspectives that things just get muddled.
          </div>
        </div>
      </div>

      <div className='book-report-container small' />
    </motion.div>
  );
}

export default BookReport;
